import React, { useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import IconCamera from "../../assets/svg/IconCamera"
// import ProfileImg from "../../assets/images/Profile.png"
import Input from '../../components/ui/Input'
import PhoneInput from 'react-phone-input-2'
import { Form, FormikProvider, useFormik } from 'formik'
import BasicFileUpload from "../../components/ui/BasicFileUpload";
import { SOCIAL_API, USER_API_URL } from '../../api/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import UseStore, { authStore } from '../../context/AuthProvider'
import { files } from '../../Helper'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import Loader from '../../components/ui/Loader'
import { t } from 'i18next'
import { profileDetailValidation } from '../../validation/CommonValidation'
import EmailAuthentication from '../../components/model/EmailAuthentication'
import MobileVerifiaction from '../../components/model/MobileVerifiaction'
import { values } from 'lodash'
import WhatsAppTelegramConfirmModal from '../../components/model/WhatsAppTelegramConfirmModal'
import WhatsAppTelegramOTP from '../../components/model/WhatsAppTelegramOTP'
import IcnWhatsapp from '../../assets/svg/IcnWhatsapp'
import IcnTelegram from '../../assets/svg/IcnTelegram'
import WhatsAppTeleViewHereModal from '../../components/model/WhatsAppTeleViewHereModal'
const Setting = () => {
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState("90"); // Set the default country code to 'in'
  const [ProfileImg, setProfileImg] = useState();
  const [Loading, setLoading] = useState();
  const [PreImg, setPreImg] = useState();
  const [selectedImg, setSelectedImg] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const setUser = authStore((state) => state?.setUser)
  const [imageName, setImageName] = useState();
  const { user } = authStore();
  const axiosInstance = useAxiosPrivate();
  const [isPhoneVerify, setIsPhoneVerify] = useState();
  const queryClient = useQueryClient();
  const [emailAuthenticationModal, setEmailAuthenticationModal] = useState(false);
  const [whatsappTelOtpModal, setWhatsappTelOtpModal] = useState(false);
  const [test, setTest] = useState();
  const [WhatsAppTelegramConfirmModalOpen, setWhatsAppTelegramConfirmModalOpen] = useState(false);
  const [resentOtpWppTel, setResentOtp] = useState(false); // resent otp state for whatsapp and telegram
  const [isTypeOption, setIsTypeOption] = useState(); // 1 = WhatsApp and 0 = Telegram
  const [changeNumber, setChangeNumber] = useState(false); // change whatsapp number state  
  const [wppNumber, setWppNumber] = useState();
  const [wppCountryCode, setWppCountryCode] = useState();


  async function getUserProfile() {
    const response = await axiosInstance.get(USER_API_URL.userProfile);
    const updatedUser = {
      ...user,
      ...response?.data
    };
    setUser(updatedUser);
    return response.data;
  }
  const { isLoading, data: userData, refetch } = useQuery(['userProfile'], () => getUserProfile(), { keepPreviousData: true, })

  useEffect(() => {
    if (userData?.isPhoneVerify == 1) {
      setTest(true);
    }
  }, [userData?.isPhoneVerify, test])


  useEffect(() => {
    if (userData) {
      const updatedUser = {
        ...user,
        ...userData
      };
      setUser(updatedUser);
      setIsPhoneVerify(updatedUser?.isPhoneVerify)
      setProfileImg(files(userData?.profile, "image"))
      setPreImg(files(userData?.profile, "image"))
      if (userData?.countryCode !== null && userData?.mobile !== null) {
        setPhone(userData?.countryCode + userData?.mobile);
      }
      if (userData?.whatsAppNumber !== null) {
        setWppNumber(userData?.whatsAppNumber);
      }
      setValues({
        ...formik.values,
        country_code: userData?.countryCode || '',
        mobile_number: userData?.mobile || '',
        whatsAppNumber: userData?.whatsAppNumber || '',
      })
    }

  }, [userData])

  

  //  Image upload events
  const handleFileInputOnChange = (event) => {
    event.preventDefault();
    const selectedFile = event?.target?.files[0];
    if (selectedFile.size > 1 * 1024 * 1024) {
      enqueueSnackbar('File size exceeds the maximum limit of 1 MB. Please choose a smaller file.', {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return;
    }
    setSelectedImg(event?.target?.files[0]);
    let userImgSrc = URL.createObjectURL(selectedFile);
    setPreImg(userImgSrc);
  }

  const { mutateAsync: uploadImage } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.uploadUserImage, data, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        });
        if (response) {
          setImageName(response?.data?.[0]);
          return response?.data?.[0]
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors)?.map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );


  const { mutateAsync: updateProfie } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.updateProfile, data)
        if (response) {
          enqueueSnackbar(response?.data.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          setLoading(false);
          queryClient.invalidateQueries('userProfile')
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );


  const handlePhoneChange = (value, data, event, formattedValue) => {
    const countryCode = data?.dialCode;
    const phoneNumber = value?.replace(`+${countryCode}`, '');
    setCountryCode(countryCode)
    setPhone(phoneNumber);
    setValues({
      ...formik.values,
      country_code: countryCode,
      mobile_number: phoneNumber,
    });
  };

  const handlewhatsappChange = (value, data, event, formattedValue) => {
    const countryCode = data?.dialCode;
    const phoneNumber = value?.replace(`+${countryCode}`, '');
    setWppCountryCode(countryCode)
    setWppNumber(phoneNumber);
    setValues({
      ...formik.values,
      wpp_country_code: countryCode,
      wpp_mobile_number: phoneNumber,
      whatsAppNumber: phoneNumber,
      mobile_number: phoneNumber.replace(countryCode, ''),
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name !== null ? user?.name : "",
      user_name: user?.userName !== undefined ? user?.userName : "",
      country_code: userData?.countryCode ? userData?.countryCode : "",
      mobile_number: user ? userData?.mobile.replace(userData?.countryCode) : "",
      email: user ? user?.email : "",
    },

    validationSchema: profileDetailValidation,

    onSubmit: async (values) => {
      setLoading(true);
      let response = '';
      if (selectedImg) {
        const formData = new FormData();
        formData.append("images", selectedImg)
        response = await uploadImage(formData);
        setImageName(response);
      }
      let numbersAfterCountryCode;
      if (phone?.startsWith(countryCode)) {
        numbersAfterCountryCode = phone?.slice(countryCode?.length);
      }
      const data = {
        name: values?.name,
        user_name: values?.user_name,
        country_code: values?.country_code ? values?.country_code : userData?.countryCode ? userData?.countryCode : !numbersAfterCountryCode ? '90' : countryCode,
        // mobile_number: numbersAfterCountryCode ? numbersAfterCountryCode : "",
        mobile_number: values?.mobile_number ? values?.mobile_number?.replace(values?.country_code) : userData?.mobile?.replace(values?.country_code),
        whatsAppNumber: values?.whatsAppNumber ? values?.whatsAppNumber?.replace(values?.country_code) : userData?.whatsAppNumber?.replace(values?.country_code),
        email: values?.email,
        oldImage: response && user?.profile || '',
        image: response ? response : user?.profile
      }
      await updateProfie(data);
    },
  });

  const {
    handleSubmit,
    getFieldProps,
    errors,
    values,
    touched,
    setValues,
    setErrors,
    resetForm
  } = formik;

  const handleOpenEmailAuthenticationModal = () => {
    setEmailAuthenticationModal(true);
  };
  const handleCloseEmailAuthentication = () => {
    setEmailAuthenticationModal(false);
  };

  const handleVerifiedApi = async () => {

    const data = {
      "mobile": values?.mobile_number,
      "countryCode": values?.country_code,
    }

    try {
      const response = await axiosInstance.post(USER_API_URL.sendMobileOtp, data)
      if (response.status == 200) {

        enqueueSnackbar(response?.data?.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        const updatedUser = {
          ...user,
          whatsAppNumber: data?.mobile_number
        };
        setUser(updatedUser);

        handleOpenEmailAuthenticationModal()

      }

    } catch (error) {


      const errors = error?.response?.data.errors;
      Object.keys(errors).map(function (key) {

        enqueueSnackbar(errors[key], {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });

      });
    }
  }

  const handeWhatsappOtp = async (type) => {
    setIsTypeOption(type)
    if (changeNumber == true) {
      handeSentOtp();
      setWhatsappTelOtpModal(true)
    } else {
      setWhatsAppTelegramConfirmModalOpen(true)
    }
  }

  const handleCloseWhatsTelegramModal = () => {
    setWhatsAppTelegramConfirmModalOpen(false)
  }
  const handleCloseWhatsappOtpModal = () => {
    setWhatsappTelOtpModal(false)
  }

  const handeSentOtp = async () => {
    setWhatsAppTelegramConfirmModalOpen(false);
    // const data = {
    //   "mobile": values?.mobile_number,
    //   "countryCode": values?.country_code,
    // 'isFromWhatsapp': 1,
    // }

    // return false;
    const data = {
      "mobile": values?.mobile_number,
      // "countryCode": values?.country_code,
      "countryCode": 91,
      'isFromWhatsapp': 1,
      ...(resentOtpWppTel && { isFromResend: 1 })
      // "isFromResend": 0,
      // if (isFromResend == 1) "isFromResend": isFromResend,
    }
    console.log({ data })
    try {
      const response = await axiosInstance.post(SOCIAL_API.sendSocialOtp, data)
     
      if (response.status == 200) {
        enqueueSnackbar(response?.data?.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        setWhatsappTelOtpModal(true)
      }
    } catch (error) {
      const errors = error?.response?.data.errors;
      Object.keys(errors).map(function (key) {
        enqueueSnackbar(errors[key], {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });

      });
    }
  }

  return (
    <>
      {isLoading ? <Loader /> :
        <div className="bg-card min-h-full mx-auto flex flex-col p-12">
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <div className="relative w-max mx-auto mb-20">
                <BasicFileUpload
                  accept="image/png, image/jpeg"
                  avatar={ProfileImg}
                  PreImg={PreImg}
                  userData={userData}
                  onChange={handleFileInputOnChange}
                />
              </div>
              <Input
                className="mb-8 bg-dark"
                lableClass="text-14"
                lable={t("Name")}
                errorMsg={errors.name && touched.name ? errors.name : null}
                placeholder={t("enter_name")}
                {...getFieldProps('name')}
              />
              <Input
                className="mb-8 bg-dark"
                lableClass="text-14"
                lable={t("User_Name")}
                errorMsg={errors.user_name && touched.user_name ? errors.user_name : null}
                placeholder={t("enter_username")}
                {...getFieldProps('user_name')}

              />
              <Input
                disabled
                className="mb-8 bg-dark truncate !pe-[76px]"
                lableClass="text-14"
                lable={t("Email_Address")}
                placeholder="Enter New Email Address"
                // suffix={
                //   <span onClick={() => handleVerifiedApi()}>
                //     {userData?.isPhoneVerify == 1 ? 'Verified' : 'Unverified'}

                //   </span>
                // }
                {...getFieldProps('email')}

              />
              <div className="mb-[20px]">
                <label htmlFor="" className="!text-white text-14 font-500 mb-4 block">
                  {t("Mobile_Number")}
                </label>
                <div className="bg-dark rounded-full p-3 flex items-center w-full relative border border-light-100">
                  <PhoneInput
                    country={'tr'}
                    value={phone}
                    className="w-full"
                    inputClass="!bg-transparent !border-0 text-white !w-full p-[14px] rounded-full !cursor-text"
                    buttonClass="!bg-transparent !border-0 hover:!bg-transparent"
                    onChange={handlePhoneChange}
                    disabled={(isPhoneVerify == 1 || test == 1) ? true : false}
                  />
                  <span className={`absolute cursor-pointer ${(isPhoneVerify == 1 || test == 1) ? 'bg-linear-horizontal' : 'bg-red'} !text-white text-[14px] p-3 rounded-full right-[50px] top-[50%] transform translate-x-1/2 -translate-y-1/2 font-500`} onClick={() => {
                    if (isPhoneVerify == 0) {
                      handleVerifiedApi()
                    }
                  }
                  }
                  >
                    {(userData?.isPhoneVerify == 1 || (test == 1 && test !== undefined)) ? 'Verified' : 'Unverified'}

                  </span>
                </div>
                <p className='text-gray text-[12px] text-center'>{(userData?.isPhoneVerify == 1 || (test == 1 && test !== undefined)) ? "" : 'Tap on unverified button to verify your number'}</p>
              </div>

              {/* -------------------------------------------- whatsApp and telegram tab button ------------------------ */}

              <div className="flex items-center gap-[12px] pb-[20px]">
                {(userData?.isWhatsAppVerify == 0 && changeNumber == false) &&
                  <div className="border border-white/40 p-[8px] rounded-[10px] flex items-center gap-[4px] cursor-pointer" onClick={() => handeWhatsappOtp(1)}>
                    <div className="border border-white/40 rounded-full p-[8px]">
                      <IcnWhatsapp className='h-[20px] w-[20px]' />
                    </div>
                    <p className='text-white text-[12px] font-normal'>Connect With Whatsapp</p>
                  </div>
                }

                {/* <div className="border border-white/40 p-[8px] rounded-[10px] flex items-center gap-[4px] cursor-pointer" onClick={() => handeWhatsappOtp(0)} >
                  <div className="border border-white/40 rounded-full p-[8px]">
                    <IcnTelegram className='h-[20px] w-[20px]' />
                  </div>
                  <p className='text-white text-[12px] font-normal'> {userData?.isTelegramVerify == 0 ? 'Connect With Telegram' : ' Connected With Telegram '} </p>
                </div> */}
              </div>

              {/* ------------------------------------ whatsApp number field --------------------------------------- */}
              {(userData?.isWhatsAppVerify == 1 || (userData?.isWhatsAppVerify == 0 && changeNumber == true)) &&
                <div className="mb-[20px]">
                  <label htmlFor="" className="!text-white text-14 font-500 mb-4 block">
                    Whatsapp Number
                  </label>
                  <div className="bg-dark rounded-full p-3 flex items-center w-full relative border border-light-100">
                    <PhoneInput
                      country={'tr'}
                      value={wppNumber}
                      className="w-full"
                      inputClass="!bg-transparent !border-0 text-white !w-full p-[14px] rounded-full !cursor-text"
                      buttonClass="!bg-transparent !border-0 hover:!bg-transparent"
                      onChange={handlewhatsappChange}
                      disabled={changeNumber == false || userData?.isWhatsAppVerify == 1 ? true : false}
                    />
                    <span className={`absolute cursor-pointer ${(userData?.isWhatsAppVerify == 1) ? 'bg-linear-horizontal' : 'bg-red'} !text-white text-[14px] p-3 rounded-full right-[50px] top-[50%] transform translate-x-1/2 -translate-y-1/2 font-500`} onClick={() => {
                      // if (isPhoneVerify == 0) {
                      handeWhatsappOtp(1)
                      // }
                    }
                    }
                    >
                      {userData?.isWhatsAppVerify == 1 ? 'Verified' : 'Send'}

                    </span>
                  </div>
                  <p className='text-gray text-[12px] text-center'>{(userData?.isWhatsAppVerify == 0) ? "Tap on send button to verify your number" : ''}</p>
                  {/* <p className='text-gray text-[12px] text-center'>Tap on unverified button to verify your number</p> */}
                </div>
              }

              {/* <div className="mb-[20px]">
                <label htmlFor="" className="!text-white text-14 font-500 mb-4 block">
                  Telegram Number
                </label>
                <div className="bg-dark rounded-full p-3 flex items-center w-full relative border border-light-100">
                  <PhoneInput
                    country={'tr'}
                    value={phone}
                    className="w-full"
                    inputClass="!bg-transparent !border-0 text-white !w-full p-[14px] rounded-full !cursor-text"
                    buttonClass="!bg-transparent !border-0 hover:!bg-transparent"
                    onChange={handlePhoneChange}
                    disabled={(userData?.isTelegramVerify == 1) ? true : false}
                  />
                  <span className={`absolute cursor-pointer ${(userData?.isTelegramVerify == 1) ? 'bg-linear-horizontal' : 'bg-red'} !text-white text-[14px] p-3 rounded-full right-[50px] top-[50%] transform translate-x-1/2 -translate-y-1/2 font-500`} onClick={() => {
                    // if (isPhoneVerify == 0) {
                    handeWhatsappOtp(1)
                    // }
                  }
                  }
                  >
                    {(userData?.isTelegramVerify == 1) ? 'Verified' : 'Unverified'}

                  </span>
                </div>
                <p className='text-gray text-[12px] text-center'>{(userData?.isTelegramVerify == 0) ? "Tap on unverified button to verify your number" : ''}</p>
              </div> */}


              <div className="mt-auto">
                <Button size="sm" className={`w-full mt-20 text-white text-[14px] ${Loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                  {Loading ? t("loading") : t("Save_Change")}
                </Button>
              </div>
            </Form>
          </FormikProvider>

        </div>}

      <MobileVerifiaction
        open={emailAuthenticationModal}
        data={values}
        setTest={setTest}
        handleClose={handleCloseEmailAuthentication}
        setClose={setEmailAuthenticationModal}
      />
      <WhatsAppTelegramOTP
        open={whatsappTelOtpModal}
        data={values}
        setResentOtp={setResentOtp}
        handeSentOtp={handeSentOtp}
        handleClose={handleCloseWhatsappOtpModal}
        setClose={setWhatsappTelOtpModal}
      />

      <WhatsAppTelegramConfirmModal handeSentOtp={handeSentOtp} setChangeNumber={setChangeNumber} open={WhatsAppTelegramConfirmModalOpen} data={values} handleClose={handleCloseWhatsTelegramModal} />
    </>
  );
};

export default Setting;
