import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import IconClose from "../../assets/svg/IconClose";
import { Button } from "../ui/Button";
import "react-phone-input-2/lib/style.css";
import PinInput from "react-pin-input";
import { authStore } from "../../context/AuthProvider";
import { maskEmail } from "../../Helper";
import { AUTH_API_URL } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { t } from 'i18next'

const WhatsAppTeleViewHereModal = ({ open, handleClose, }) => {


  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={() => { return; }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {/* <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all"> */}
                <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all">
                  <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                    <p className="text-white ms-auto">Why we need your WhatsApp number?</p>
                    <IconClose
                      onClick={() => {
                        handleClose()
                      }}
                      className="text-white w-[18px] ms-auto cursor-pointer"
                    />
                  </div>
                  <div className="p-12">
                    {/* <p className="text-gray text-16 font-400">{t("OTP_has_been_sent_on")}</p> */}
                    <ul className="mb-[40px] flex flex-col items-center gap-[8px] list-disc ps-10">
                      <li className="text-white text-[14px] font-400">On this number we will send a one-time password (OTP) on your WhatsApp to verify apple integration.</li>
                      <li className="text-white text-[14px] font-400">This number will also be used for customer support and important updates, if needed.</li>
                      <li className="text-white text-[14px] font-400">Your number will only be used for these purposes, ensuring your privacy is respected.</li>
                    </ul>
                   
                  
                    <Button className={`w-full text-[14px] text-white`} onClick={()=>{ handleClose()}} >Understood</Button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default WhatsAppTeleViewHereModal;
