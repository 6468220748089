import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import IconClose from "../../assets/svg/IconClose";
import { Button } from "../ui/Button";
import "react-phone-input-2/lib/style.css";
import PinInput from "react-pin-input";
import { authStore } from "../../context/AuthProvider";
import { maskEmail } from "../../Helper";
import { AUTH_API_URL } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { t } from 'i18next'
import WhatsAppTeleViewHereModal from "./WhatsAppTeleViewHereModal";

const WhatsAppTelegramConfirmModal = ({ open, data, handleClose, handeSentOtp, setChangeNumber }) => {
  const { user, set } = authStore();
  const [otp, setOtp] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const axiosInstance = useAxiosPrivate();
  const [seconds, setSeconds] = useState(90);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const queryClient = useQueryClient()
  const [isDisable, setIsDisable] = useState(true);
  const [viewHearModalOpen,setViewHereModal] = useState(false);






  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={() => { return; }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {/* <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all"> */}
                <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all">
                  <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                    <p className="text-white ms-auto">WhatsApp Number Confirmation</p>
                    <IconClose
                      onClick={() => {
                        handleClose()
                        setIsResendEnabled(false)

                      }}
                      className="text-white w-[18px] ms-auto cursor-pointer"
                    />
                  </div>
                  <div className="p-12 text-center">
                    {/* <p className="text-gray text-16 font-400">{t("OTP_has_been_sent_on")}</p> */}
                    <p className="text-white text-16 mb-8 font-500 text-center">
                      Is your WhatsApp number the same as your mobile number ? (+{data?.country_code} {data?.mobile_number} )
                    </p>
                    <p className="text-gray text-[14px] font-400 text-center">
                      Why do we need your WhatsApp number ? 
                    </p>
                    <p className="text-[14px] cursor-pointer !text-[#7684ed]" onClick={()=>setViewHereModal(true)}>Tap to view</p>
                    <div className="mt-auto">
                      {
                        <div className="flex items-center justify-end gap-[16px] pt-[20px]">
                          <Button type='button' className="text-[14px] font-500 text-white p-0 bg-transprant outline-none" color="error" 
                          onClick={() => {
                            setChangeNumber(true);
                            handleClose();
                            setIsResendEnabled(false);
                          }}>No</Button>
                          <Button type='button' className="text-[14px] font-500 text-green p-0 bg-transprant outline-none" onClick={() => handeSentOtp()}>Yes,Same as mobile</Button>
                        </div>
                      }
                    </div>
                    {/* <Button className={`w-full text-[14px] text-white ${isDisable ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={isDisable} >{t("Submit")}</Button> */}
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <WhatsAppTeleViewHereModal  open={viewHearModalOpen}  handleClose={()=>setViewHereModal(false)}   />

    </>
  );
};

export default WhatsAppTelegramConfirmModal;
