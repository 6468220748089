import React from "react";
import { Button } from "../../ui/Button";
import IconArrowRight from "../../../assets/svg/IconArrowRight";

const AboutExplore = () => {
  return (
    <section className="relative lg:mt-[200px] md:mt-[130px] sm:mt-[90px] mt-[30px] bg-dark sm:px-[25px] w-full h-full bg-[url('./../src/assets/images/AboutMaskBg.png')] bg-no-repeat sm:bg-auto xxl:bg-cover bg-cover">
      <div className="container relative z-[1]">
        <div className="grid sm:grid-cols-2 justify-items-center lg:gap-8">
          <div className="flex flex-col sm:items-start items-center max-w-[380px] md:py-[80px] py-[40px] lg:gap-[40px] gap-[24px]">
            <h3 className="text-white lg:text-32 text-24 font-600" data-aos="fade-down" data-aos-duration="1500">
              Let us take you to explore crypto industry
            </h3>
            <Button variant="filled" className="flex gap-4 text-14 font-700 items-center w-max group text-white bg-linear-horizontal border-0" data-aos="fade-right" data-aos-duration="2500">
              Get Started <IconArrowRight className="w-[24px] text-white" />
            </Button>
          </div>
          <div className="relative w-full">
            <img
              src={require("../../../assets/images/WebTransaction.webp")}
              className="sm:absolute bottom-0 sm:w-auto w-[80%] sm:mx-0 mx-auto"
              alt=""
              data-aos="zoom-in" data-aos-duration="1500"
            /> 
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutExplore;
