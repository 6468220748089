import React from 'react'

const IcnWhatsapp = (props) => {
    return (
        <svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.9661 24.1348C10.5277 24.1348 8.26686 23.3959 6.36325 22.1479L1.76389 23.6156L3.25103 19.153C1.81354 17.1964 0.960815 14.7404 0.960815 12.1148C0.960815 11.7254 0.990462 11.356 1.04008 10.9667C1.62503 4.88674 6.76025 0.134766 12.9661 0.134766C19.2708 0.134766 24.4555 5.01657 24.9409 11.1863C24.9608 11.5057 24.9608 11.8251 24.9608 12.1147C24.9608 18.7337 19.5785 24.1347 12.9661 24.1347L12.9661 24.1348Z"
                fill="#33CC33" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M17.1296 13.7422C16.7819 13.6524 16.5644 13.5725 16.3164 13.942C15.9593 14.511 15.206 15.7589 14.4726 15.3895C14.106 15.2198 12.986 14.8504 11.6475 13.6524C10.6064 12.7139 9.89304 11.5859 9.70401 11.2464C9.39718 10.6674 10.368 10.0584 10.7252 9.32962C10.8439 9.08998 10.7945 8.89033 10.6957 8.72061C10.6264 8.55091 9.92247 6.81383 9.62517 6.12496C9.33732 5.4161 9.04001 5.53598 8.85192 5.53598C6.90836 5.53598 5.99646 6.92367 5.99646 8.87031C5.99646 9.25965 6.07624 9.67906 6.19498 10.0484C6.5417 11.2963 7.31494 12.3046 7.43461 12.4743C7.60276 12.7139 9.84363 16.3479 13.402 17.7457C16.9804 19.1432 16.9804 18.674 17.615 18.624C18.24 18.5743 19.6773 17.7953 19.9746 16.9568C20.2624 16.1482 20.2624 15.4392 20.1627 15.2896C20.0439 15.08 17.1296 13.7522 17.1296 13.7422V13.7422Z"
                fill="white" />
        </svg>
    )
}

export default IcnWhatsapp
