import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import IconClose from '../../assets/svg/IconClose'
import IcnCopy from '../../assets/svg/IcnCopy'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'

const FullCardDetailModal = ({ open, handleClose, detail, detailData, balance }) => {

  const { enqueueSnackbar } = useSnackbar()


  const numberFormate = (number) => {
    const set1 = number.slice(0, 4)
    const set2 = number.slice(4, 6)
    const set3 = number.slice(-4)
    const fullNumber = set1 + ' ' + number.slice(4, 8) + ' ' + number.slice(9, 13) + ' ' + set3
    return fullNumber;
  }

  const handleCopy = (text) => {
    navigator?.clipboard?.writeText(text)
      .then(() => {
        enqueueSnackbar("Copied to clipboard!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        console.error('Unable to copy text to clipboard', error);
        // Handle the error or provide user feedback
      });
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{t("Full_Card_Details")}</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>

                <div className="p-12">
                  <h6 className='text-gray text-14 mb-4'>{t("Name")}</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.firstName} {detail?.lastName}</p>
                  <h6 className='text-gray text-14 mb-4'>{t("Email_Address")}</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.email}</p>
                  <h6 className='text-gray text-14 mb-4'>Card Number</h6>
                  <div className="border-b border-b-light-200 pb-4 mb-4 flex items-center justify-between">
                    <p className='text-white'>{numberFormate(detail?.card_number ?? detail?.bankCardInfo?.card_number)}</p>
                    <span className='h-[32px] w-[32px] bg-linear-horizontal rounded-full flex-shrink-0 flex items-center justify-center cursor-pointer'>
                      <IcnCopy className="h-[20px] w-[20px] text-white" onClick={() => handleCopy(detail?.card_number ?? detail?.bankCardInfo?.card_number)} />
                    </span>
                  </div>



                  <h6 className='text-gray text-14 mb-4'>CVV</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.bankCardInfo?.cvv ?? detail?.cvv}</p>


                  {/* <h6 className='text-gray text-14 mb-4'>Single Limit</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.maxAmountSingle ?? '-'}</p>
                  <h6 className='text-gray text-14 mb-4'>Daily Limit</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.maxAmountDaily ?? '-'}</p>
                  <h6 className='text-gray text-14 mb-4'>Monthly Limit</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.maxAmountMonthly ?? '-'}</p> */}

                  <h6 className='text-gray text-14 mb-4'>Account Status</h6>
                  <p className='text-theme pb-4 border-b border-b-light-200 mb-4'>{detail?.cardIdStatus == 0 ? 'Pending' : detail?.cardIdStatus == 1 ? t('Verified') : 'Rejected'}</p>

                  <h6 className='text-gray text-14 mb-4'>Freeze/UnFreeze</h6>
                  <p className='text-theme pb-4 border-b border-b-light-200 mb-4'>{detail?.cardFreezeStatus == 2 ? 'Unfreeze' : 'Freeze'}</p>

                  {detail?.hypercardtype?.minSingleRechargeAmount?.toFixed(2) &&
                    <div className="pb-4 border-b border-b-light-200 mb-4">
                      <p className='text-gray text-14 mb-4'>Recharge info:</p>
                      <h6 className='text-white text-[13px] mb-4'>Available card balance : <span className="text-[#7684ED]">{balance} {detail?.hypercardtype?.cardCurrency}</span></h6>
                      <h6 className='text-white text-[13px] mb-4'>One time min recharg : <span className="text-[#7684ED]">{detail?.hypercardtype?.minSingleRechargeAmount?.toFixed(2)}  {detail?.hypercardtype?.cardCurrency}</span></h6>
                      <h6 className='text-white text-[13px] mb-4'>One time max recharge : <span className="text-[#7684ED]">{detail?.hypercardtype?.maxSingleRechargeAmount?.toFixed(2)}  {detail?.hypercardtype?.cardCurrency}</span></h6>
                      <h6 className='text-white text-[13px] mb-4'>Daily max card recharge : <span className="text-[#7684ED]">{detail?.hypercardtype?.maxRechargeAmount?.toFixed(2)}  {detail?.hypercardtype?.cardCurrency}</span></h6>
                    </div>

                  }

                  
                    {detail?.hypercardtype?.oneTimeSpent !== undefined && 
                      <>
                        <h6 className='text-gray text-14 mb-4'>One Time Spent</h6>
                        <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.hypercardtype?.oneTimeSpent}  {detail?.hypercardtype?.cardCurrency}</p>
                      </>
                    }
                  {detail?.hypercardtype?.monthlySpent !== undefined ? <>
                    <h6 className='text-gray text-14 mb-4'>Monthly Spent</h6>
                    <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.hypercardtype?.monthlySpent?.toFixed(2)}  {detail?.hypercardtype?.cardCurrency}</p>
                  </> : ''
                  }

                  {detail?.cardFreezeRequestStatus !== undefined &&
                    (
                      detail?.cardFreezeRequestStatus !== 1 &&
                      <ul className='flex items-center justify-center list-disc'>
                        <li className={`${detail?.cardFreezeRequestStatus === 2 ? 'text-red' : 'text-[#cfba54]'} text-[16px]`}>{detail?.cardFreezeRequestStatus === 0 ? 'Your freez card request is in under process.' : detail?.cardFreezeRequestStatus === 2 ? 'Your freez card request has been rejected' : detail?.cardFreezeRequestStatus === 99 ? 'Please waiting for third party' : detail?.cardFreezeRequestStatus === 98 ? 'Pending payment' : ''} </li>
                      </ul>)
                  }

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default FullCardDetailModal
