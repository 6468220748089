import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import IconAddress from "../../assets/svg/IconAddress"
import Input from '../../components/ui/Input'
import CustomSelect from '../../components/ui/CustomSelect'
import { useLocation, useNavigate } from 'react-router-dom'
import { SEND_API_URL, SWAP_API_URL, WALLET_API_URL } from '../../api/api'
import { useMutation, useQuery } from 'react-query'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { Form, FormikProvider, useFormik } from 'formik'
import { authStore } from '../../context/AuthProvider'
import { useSnackbar } from 'notistack'
import { sendTransactionSchema } from '../../validation/CommonValidation'
import { useTranslation } from 'react-i18next'
import { _randomString, fixDecimal, generateJwt, seperateToken } from '../../Helper'
import FeeLevelDialog from '../../components/ui/FeeLevelDialog'
import SendConfirmationDialog from '../../components/ui/SendConfirmationDialog'
import { AuthSocketContext } from '../../context/AuthSocketProvider'
import Swal from 'sweetalert2'
import SendInformation from '../../components/ui/SendInformation'
import { RadioGroup } from '@headlessui/react'
import IconDone from '../../assets/svg/IconDone'
import * as Yup from "yup";
import { values } from 'lodash'

const Send = () => {
  const navigate = useNavigate();
  const axiosInstance = useAxiosPrivate();
  const location = useLocation();
  const [coins, setCoins] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(undefined);
  const [address, setAddress] = useState();
  const { user, userVaultAccount, encKey, set, userCurrencyBalance } = authStore();
  const [loggedInUser, setLoggedInUser] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [level, setLevel] = useState();
  const [selectedFeeLevel, setSelectedFeeLevel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrencyUserAmount, setSelectedCurrencyUserAmount] = useState(null);
  const [userInputValues, setUserInputValues] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSendInfo, setOpenSendInfo] = useState(false)
  const { setConfirmSend, approveReject, setApproveReject } = useContext(AuthSocketContext)
  const [plan, setPlan] = useState(undefined);
  const [feeLevel, setFeeLevel] = useState(undefined)
  const [currencyBalance, setCurrencyBalance] = useState([]);

  const keysArray = Object.keys(level ? level : '');

  //  step 1  : fetch Coins
  async function getCoin() {
    const response = await axiosInstance.get(WALLET_API_URL.getToken);
    return response.data;
  }
  const { data: coinList } = useQuery(['coinList'], () => getCoin(), { keepPreviousData: true, })

  // set send from validation 

  // const sendTransactionSchema = (t, selectedCoin, currencyBalance) => Yup.object().shape({
  //   coin: Yup.string().required(t('Please_select_currency')),
  //   address: Yup.string().required(t('Please_enter_or_select_address_from_the_address_book')),
  //   amount: Yup.number().required(t('Please_enter_amount_to_transfer')).test(
  //     'no-signs',
  //     t('Please enter valid amount'),
  //     (value) => /^[0-9]*\.?[0-9]+$/.test(value) // Regex to ensure no '+' or '-' signs
  //   ).test(
  //     'is-less-than-balance',
  //     t(`Amount should be less than ${currencyBalance?.[0]?.newBalance}`), // Custom message
  //     (value) => value < currencyBalance?.[0]?.newBalance // Ensure the amount is strictly less than the balance
  //   ),
  // });

  const sendTransactionSchema = (t, selectedCoin, currencyBalance) =>
    Yup.object().shape({
      coin: Yup.string().required(t('Please_select_currency')),
      address: Yup.string().required(t('Please_enter_or_select_address_from_the_address_book')),
      amount: Yup.number()
        .required(t('Please_enter_amount_to_transfer'))
        .test(
          'no-signs',
          t('Please enter valid amount'),
          (value) => /^[0-9]*\.?[0-9]+$/.test(value) // Regex to ensure no '+' or '-' signs
        )
        .test(
          'is-positive',
          t('Amount must be greater than 0'), // Custom message
          (value) => value > 0 // Ensure the amount is greater than 0
        )
        .test(
          'is-less-than-balance',
          t(`Amount should be less than ${currencyBalance?.[0]?.newBalance ?? 0}`), // Custom message
          (value) => value < currencyBalance?.[0]?.newBalance // Ensure the amount is strictly less than the balance
        ),
    });


  useEffect(() => {
    if (coinList) {
      const activeCoins = coinList?.filter(coin => coin.status === 1);
      setCoins(activeCoins);
    }
  }, [coinList])

  // step 2 : get selected Coin
  const handleSelectedCoin = (value) => {

    const matchedBalance = userCurrencyBalance?.filter(item => item.id == value?.id);
    setCurrencyBalance(matchedBalance);
    setSelectedCoin(value)
    formik.setFieldValue('coin', value?.decimal);
    setOpen(false)
    resetForm();
  }
  // step 3 : fetch users currency amount on selected

  useEffect(() => {
    if (selectedCoin && userVaultAccount) {
      const userValutAmount = userVaultAccount?.assets?.find(account => account.id === selectedCoin?.symbol);
      setSelectedCurrencyUserAmount(userValutAmount)
    }
  }, [selectedCoin])

  useEffect(() => {
    setAddress(location?.state?.address)
    if (location?.state?.coin) {
      setSelectedCoin(location?.state?.coin)
    } else if (location?.state?.token) {
      setSelectedCoin(location?.state?.token)
    }
  }, [location])

  const selectAddress = () => {
    navigate("/dashboard/select-address", { state: { coin: selectedCoin } })
  }

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    if (values?.amount > selectedCurrencyUserAmount?.balance) {
      enqueueSnackbar("Your transaction amount must be less than to your available balance.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return;
    }
    setIsLoading(true);
    // const coin = selectedCoin?.code ? selectedCoin?.code : selectedCoin?.symbol;
    values.selectedCoin = selectedCoin
    //  Api Step 1: validate Address

    if (values?.address) {
      setUserInputValues(values);
      const data = {
        currency: selectedCoin?.symbol == 'TRX' ? 'TRON' : selectedCoin?.symbol == 'LTC' ? 'TRON' : selectedCoin?.symbol ,
        address: values?.address
      };
      console.log({selectedCoin,data,values})

      if(selectedCoin?.symbol == 'LTC' || selectedCoin.symbol == 'BTC' || selectedCoin.symbol == 'DOGE'){
        // setOpenSendInfo(true)
        // setConfirmSend(data)
        // resetForm()
        setOpenConfirmation(true)
      }else{

        await validateAddress({ ...data, values }); // Pass userInputValues as an argument
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      coin: selectedCoin ? selectedCoin?.decimal : "",
      address: address ? address?.address : "",
      amount: "",
      tag: ""
    },
    validationSchema: sendTransactionSchema(t, selectedCoin, currencyBalance),
    onSubmit: onSubmit,

  });

  // Validate address api
  const { mutateAsync: validateAddress } = useMutation(
    async (data) => {
      return axiosInstance.post(SEND_API_URL.validateAddress, data).then((res) => res.data);
    },
    {
      onSuccess: async (data, variables) => {
        // Api Step 2: get transactionEstimateFee 
        setIsLoading(false)
        setOpenConfirmation(true)
        const estimateAddData = {
          assetId: selectedCoin?.symbol == 'TRX' ? 'TRON' : selectedCoin?.symbol,
          amount: variables?.values?.amount,
          // sourceId: user?.vaultAccountAssetId,
          destinationIdOrAddress: userInputValues?.address ? userInputValues?.address : address?.address
        };
      },
      onError: async (error) => {
        setIsLoading(false);
        if (error?.response?.data.message) {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
        }
      },
    }
  );

  const {
    handleSubmit,
    getFieldProps,
    values,
    errors,
    touched,
    setErrors,
    resetForm
  } = formik;

  const handleModalClose = () => {
    resetForm();
  }

  const handleCloseConfirmation = () => {
    setIsLoading(false)
    setOpenConfirmation(false)
    setAddress(null)
  }

  useEffect(() => {
    document.title = 'Send | Oppi Wallet'
  }, [])

  const handleSubmitConfirm = () => {
    setIsLoading(false)
    const payabelAmount = parseFloat(userInputValues?.amount) + parseFloat(selectedFeeLevel?.networkFee);
    if (parseFloat(payabelAmount) > parseFloat(selectedCurrencyUserAmount?.balance)) {
      enqueueSnackbar(`Your current balance is ${selectedCurrencyUserAmount?.balance} and total transfer amount including estimateFee is ${payabelAmount} !`, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return
    }

    const data = {
      userId: user?._id,
      currencyName: (selectedCoin?.tokenId ? `${selectedCoin?.tokenName?.toUpperCase()}(${selectedCoin?.symbol})` : selectedCoin?.name),
      currencyCode: selectedCoin?.symbol,
      receiverAddress: values?.address,
      amount: (userInputValues?.amount).toString(),
      encryptedKey: encKey,
      tokenId: selectedCoin?.id ?? '',
      tag: userInputValues?.tag ?? ""
    }
    setOpenConfirmation(false)
    setOpenSendInfo(true)
    setConfirmSend(data)
    resetForm()
  }

  useEffect(() => {
    if (approveReject !== null) {
      if (approveReject?.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Your transaction request has been approved.",
          timer: null,
          showConfirmButton: true,
          allowOutsideClick: false
        });

      } else if (approveReject?.status === 2) {
        Swal.fire({
          icon: "error",
          title: "Send Transaction?",
          text: "Your transaction request has been rejected.",
          timer: null,
          showConfirmButton: true,
          allowOutsideClick: false
        });
      }
      setApproveReject(null)
      setSelectedFeeLevel(null)
      setSelectedCoin(undefined)
      setAddress(null)
      setConfirmSend(null)
      setOpenSendInfo(false)
      set({ isSendPayment: false })
    }
  }, [approveReject])

  const handleCloseInfoDialog = () => {
    setOpenSendInfo(false)
  }

  const handleClick = () => {
    if (plan) {
      // handleGetFeeLevel(plan)
    } else {
      enqueueSnackbar("Please select estimate fees to proceed", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  }

  const amount = getFieldProps('amount')

  useEffect(() => {
    setOpen(false)
  }, [amount.value])

  useEffect(() => {
    const defaultFeeLevel = level?.low;
    setPlan(defaultFeeLevel);
    const feeLevelsData = {};
    for (let key of keysArray) {
      const property = level[key];
      property.level = key;
      feeLevelsData[key] = property;
    }
    setFeeLevel(feeLevelsData);
  }, [level]);

  return (
    <>
      <div className="p-12 bg-dark h-full">
        <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
          <div className="flex justify-between items-center mb-12">
            <p className='text-white text-20 font-500'>{t('Send')}</p>
            {
              selectedCoin && (
                <div className="py-2 px-8 bg-dark flex items-center gap-4 rounded-full border border-light-100 cursor-pointer" onClick={selectAddress}>
                  <IconAddress className="text-white w-[20px]" />
                  <p className='text-white'>{t('Address')}</p>
                </div>
              )
            }
          </div>
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8'>
                {t('Select_Currency')}
              </label>
              <CustomSelect listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px]" data={coins}
                getChildData={handleSelectedCoin} selectedCoin={selectedCoin} errorMsg={errors.coin && touched.coin ? errors.coin : null} type={'send'} />
              {
                selectedCoin || !address?.length == 0 ? (
                  <>
                    <div className='mt-8'>
                      <Input lable={t('Address_or_name')} lableClass="text-14" className="bg-dark" placeholder="ENS, .bnb, .eth, .crypto etc..." {...getFieldProps('address')} errorMsg={errors.address && touched.address ? errors.address : null} />
                      <Input type='number' lable={t('Amount')} lableClass="text-14 mt-8" className="bg-dark" placeholder={t('Enter_Amount_in_INR')}  {...getFieldProps('amount')} errorMsg={errors.amount && touched.amount ? errors.amount : null} />
                      <div>
                        <p className='text-white text-14 m-2 flex items-center gap-[4px] '>{t('Available_Balance')} :  <div className='flex items-center gap-[5px]'><span>{parseFloat(fixDecimal(currencyBalance?.[0]?.newBalance)) || "0.0"}</span><span>{currencyBalance?.[0]?.symbol ?? ""}</span> </div></p>
                      </div>
                      {
                        selectedCoin?.name == "XRP" && (
                          <Input type='text' lable="Meta Tag" lableClass="text-14 mt-8" className="bg-dark" placeholder="Enter Tag" {...getFieldProps('tag')} errorMsg={errors.tag && touched.tag ? errors.tag : null} />
                        )
                      }
                    </div>
                    {open &&
                      <div className="mt-[20px] p-[16px] border border-light-100 max-w-[400px] rounded-[10px]">
                        <p className='text-[18px] font-600 text-white'>{t('Choose_Estimate_fee')}</p>
                        <div className="content p-4">
                          <RadioGroup className="flex flex-col text-18 gap-4" value={plan} onChange={setPlan}>
                            {keysArray?.map((item, index) =>
                              feeLevel && feeLevel[item] && (
                                <RadioGroup.Option key={index} value={feeLevel[item]} className="cursor-pointer">
                                  {({ checked }) => (
                                    <div className="flex gap-2 text-14 font-500 text-white">
                                      <div
                                        className={`flex justify-center items-center w-8 h-8 rounded-full text-gray ${checked ? "bg-theme" : "border"
                                          }`}
                                      >
                                        {checked && <IconDone className="w-[9px] text-black" />}
                                      </div>
                                      {`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                                      <div>
                                        <div className='!text-white'>{` - ` + parseFloat(feeLevel[item]?.networkFee)?.toFixed(8) + ``} {selectedCoin?.tokenCode ? selectedCoin?.tokenCode : selectedCoin?.code}</div>
                                      </div>
                                    </div>
                                  )}
                                </RadioGroup.Option>
                              )
                            )}
                          </RadioGroup>
                        </div>
                      </div>}
                  </>
                ) : (<></>)
              }
              {errors.submit && (
                <div item xs={12}>
                  <p error>{errors.submit}</p>
                </div>
              )}
              <div className="mt-auto">
                {
                  open ?
                    <div className="grid sm:grid-cols-2 items-center gap-[40px] pt-[20px]">
                      <Button type='button' className="w-full !text-14 border text-black mt-2" color="error" onClick={handleModalClose}>Cancel</Button>
                      <Button type='button' className="w-full !text-14  border  text-black mt-2" onClick={handleClick}>Submit</Button>
                    </div> :
                    <Button className={`w-full text-[14px] mt-20 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`} size="sm" disabled={isLoading}>
                      {isLoading ? 'Loading...' : t('Continue')}
                    </Button>
                }
              </div>
            </Form>
          </FormikProvider>
        </div>
        <SendConfirmationDialog currencyBalance={currencyBalance} open={openConfirmation} setOpen={setOpenConfirmation} setIsLoading={setIsLoading} handleClose={handleCloseConfirmation} userInput={userInputValues} feeLevel={selectedFeeLevel} handleSubmitConfirm={handleSubmitConfirm} userBalance={selectedCurrencyUserAmount} />
        {/* <FeeLevelDialog open={open} setOpen={setOpen} handleClose={handleModalClose} data={level} getChildData={handleGetFeeLevel} /> */}
        <SendInformation open={openSendInfo} setOpen={setOpenSendInfo} handleClose={handleCloseInfoDialog} />
      </div>
    </>
  )
}

export default Send